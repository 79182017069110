import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiStore } from '../../../../core/store/ui.store';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cancel-button',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './cancel-button.component.html',
  styleUrl: './cancel-button.component.scss',
})
export class CancelButtonComponent {
  constructor(public uiStore: UiStore) {}

  @Input() label: string = 'Cancel';
  @Input() dismissModal: boolean = true;
  @Output() cancelRequest = new EventEmitter<void>();

  requestCancel(): void {
    this.cancelRequest.emit();
  }
}
